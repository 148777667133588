module.exports = {
    SHOW_INTERACTIVE_PI: false,
    CURRENT_SELECTED_PI: 'april-2024', // Slug for selecting correct XML file
    PI_PDF_URL: 'https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf',
    XML_FILR_DIR: './src/pi/xml',
    //  uses text matching on the title attirbute to exclude sections of the PI XML that do not need to be in the app
    JSON_SECTION_EXCLUDES: [
        // 'spl',
        'principal display panel',
        // 'carton'
    ],
    BASE_IMAGE_PATH: `/pi-images/`
}
